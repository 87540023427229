import React from 'react'
import '../../../components/styles/global.scss'
import { StaticImage } from 'gatsby-plugin-image'
import SegmentedControl from '../../../components/common/SegmentedControl'
import Layout from '../../../components/common/Layout'

export default function ClinicalTrailsET() {
    return (
        <Layout active='pipeline-essential-thrombocythemia'
        title='PharmaEssentia Medical Affairs | Clinical Trials in Essential Thrombocythemia'
        description='Learn about PharmaEssentia’s clinical trials in essential thrombocythemia and discover currently enrolling trials.'
        socialTitle='PharmaEssentia Medical Affairs | Clinical Trials in Essential Thrombocythemia'
        socialDescription='Learn about PharmaEssentia’s clinical trials in essential thrombocythemia and discover currently enrolling trials.'
        pathname='/pipeline/clinical-trials/essential-thrombocythemia'
        >
            <section>
                <div className='inner'>
                    <h1>Our Pipeline</h1>
                    <SegmentedControl buttons='our-pipeline' active='pipeline-essential-thrombocythemia'/>
                    <h3>Clinical Trials in Essential Thrombocythemia (ET)</h3>
                    <p>Our commitment to scientific excellence is the basis for our ongoing clinical research. We are deeply appreciative of the study sites, investigators, patients, caregivers, and clinicians who support our efforts through their participation. Learn more about our trial data and currently enrolling trials below.</p>
                    <div className='trials'>
                        <h5>Currently Enrolling Trials</h5>
                        
                        <div className='col'>
                            <StaticImage
                                src='../../../raster/surpass-et-logo.png'
                                alt='Surpass ET logo'
                                placeholder='none'
                                className='logo surpass'
                                />
                            <p>A Phase 3, open-label, multicenter, randomized, active-controlled trial assessing the efficacy, safety, tolerability, and pharmacokinetics of ropeginterferon alfa-2b-njft after 12 months of treatment compared with anagrelide (ANA) as second-line therapy for adult patients with ET who have shown resistance or intolerance to hydroxyurea (HU).</p>
                            <a 
                            className='off-site'
                            href='https://clinicaltrials.gov/ct2/show/NCT04285086'
                            target='_blank'
                            rel='noopener noreferrer'>ClinicalTrials.gov: NCT04285086 <span className='triangle'></span></a>

                            <div className='row gapped'>
                                <div className='col'>
                                    <div>
                                        <p>For more information about SURPASS ET:</p>
                                        <ul className='bullets'>
                                            <li><span>Call the study information center at <a className="text-link no-break" href="tel:+18009992449">1-800-999-2449</a></span></li>
                                            <li><span>Email the PharmaEssentia Medical Information team at <a className='text-link' href="mailto:medinfo@pharmaessentia-us.com?subject=Request%20for%20more%20information:%20Exceed%20ET%20Clinical%20Trial">medinfo@pharmaessentia-us.com</a></span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-end width-max-content'>
                                    <span className='flex'>
                                        <a 
                                        target='_blank'
                                        rel='noopener noreferrer'
                                        className='cta-button'
                                        href='https://www.clinicaltrials.gov/ct2/show/NCT04285086?term=surpass+et&draw=2&rank=1'
                                        >visit <span>CLINICALTRIALS.GOV</span></a>
                                    </span>
                                </div>
                            </div>

                            
                            <span className='divider'></span>

                            <div className='col'>
                                <img className='logo' src='/svg/exceed-et-logo.svg' alt='Exceed  ET logo'/>
                                <p>A Phase 2b, single-arm, multicenter trial assessing the efficacy, safety, and tolerability of ropeginterferon alfa-2b-njft in adult patients with ET in the US and Canada.</p>
                                <a 
                                className='off-site'
                                href='https://clinicaltrials.gov/ct2/show/NCT05482971'
                                target='_blank'
                                rel='noopener noreferrer'>ClinicalTrials.gov: NCT05482971 <span className='triangle'></span></a>
                                
                                <div className='row gapped'>
                                    <div className='col'>
                                        <div>
                                            <p>For more information about EXCEED ET:</p>
                                            <ul className='bullets'>
                                                <li><span>Call the study information center at <a className="text-link no-break" href="tel:+18009992449">1-800-999-2449</a></span></li>
                                                <li><span>Email the PharmaEssentia Medical Information team at <a className='text-link' href="mailto:medinfo@pharmaessentia-us.com?subject=Request%20for%20more%20information:%20Exceed%20ET%20Clinical%20Trial">medinfo@pharmaessentia-us.com</a></span></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className='col-end width-max-content'>
                                        <span className='flex'>
                                            <a 
                                            target='_blank'
                                            rel='noopener noreferrer'
                                            className='cta-button'
                                            href='http://www.exceedet.com/hcp'
                                            >visit <span>exceedet.com/hcp</span></a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                            
                        
                    </div>
                </div>
            </section>
        </Layout>
    )
}